import React, { useState } from 'react'
import { Link } from 'gatsby'
import { transparentize } from 'polished'
import { useLocation } from '@reach/router'
import _ from 'lodash'

import { isInternalUrl, splitArrayEvenly } from '../utils/helpers'
import HeaderImages from './HeaderImages'
import logoReisegarantie from '../assets/images/logos/Logo_Reisegarantie_Weiss.svg'

const stamps = {}
const destinationSlugs = [
  'asien',
  'sri-lanka',
  'russland',
  'chinareisen',
  'neuseeland',
  'arabien',
  'malediven',
  'alaska',
  'australien',
  'afrika',
  'indienreisen',
  'japan',
  'karibik',
  'suedamerika',
  'mauritiusreisen',
  'zentralasien',
  'seychellen',
  'suedsee',
  'skandinavien',
  'island',
  'zentralamerika',
  'usa',
  'kanada',
  'knecht',
  'latino',
  'kira',
  'glur',

  'golfreisen',
  'heliskiingreisen',
  'sportreisen',
  'sportlivereisen',
  'trainingslagerreisen',
  'hochzeitsreisen',
  'geschaeftsreisen',
  'bahnreisen',
  'premiumreisen'
]
destinationSlugs.forEach(slug => {
  stamps[slug] = require(`../assets/images/stamps/${slug}.svg`)
})

const headerSettings = {
  knecht: {
    hasFiliale: true
  },
  latino: {
    hasFiliale: false
  },
  kira: {
    hasFiliale: false
  },
  glur: {
    feedbackPrefix: 'skandinavien/',
    hasFiliale: false
  }
}

// Projects
const envFile = require('../../env')
const env = envFile.env[process.env.BENV || 'prod']
const allProjectsDestinations = _.map(envFile.env, 'homepage_destination')

const getExcludedSubDestinations = destination => {
  const allExcludedSubDestinations = env?.excludeSubDestinations || {}
  if (allExcludedSubDestinations.hasOwnProperty(destination)) {
    const excludedSubDestinations = allExcludedSubDestinations[destination]
    return excludedSubDestinations || []
  }

  return []
}

const isSubDestinationExcluded = (destination, subDestination) => {
  const excludedSubDestinations = getExcludedSubDestinations(destination)

  if (excludedSubDestinations && excludedSubDestinations.length > 0) {
    return excludedSubDestinations.includes(subDestination)
  }

  return false
}

const Header = ({ pageContext }) => {
  const { page, destination, isRootPage, langSlug, lang, url } = pageContext

  // console.log('Header PC: ', pageContext)

  const destinationName = destination?.name?.value
  const destinationSlug = destination?.slug?.value
  const destinationStampImage = stamps[destinationSlug] || null
  const destinationColor = destination?.color?.value || 'white'
  const phone = env.phone_number || destination?.phone?.value
  const showDestinationsOverlay = destination?.showDestinationsOverlay?.value

  const destinationMenuTitle = destination?.menuTitle?.value || 'Destinationen'
  const allDestinationMenuItems = destination?.menuItems?.value || []

  const jobsLink = env?.jobsLink
  if (!jobsLink?.isExternal) {
    if (jobsLink?.link && jobsLink.link.charAt(0) === '/') {
      jobsLink.link = jobsLink.link.slice(1)
    }
  }

  const sanitiseSubDestination = subDestination => {
    if (!subDestination) {
      return null
    }
    return subDestination.replace(`${destinationSlug}/`, '').trim()
  }

  // remove excluded sub-destinations from list
  const destinationMenuItems = allDestinationMenuItems?.filter(item => {
    const url = item.value?.url?.value
    if (!url) {
      return false
    }
    const subDestination = sanitiseSubDestination(url)
    return isSubDestinationExcluded(destinationSlug, subDestination) === false
  })

  // console.log('Destination menu items: ', destinationMenuItems)

  const headerSetting = headerSettings[env.homepage_destination]
  // get number of items in 'Destinations' menu;
  // filter only items with URL - some of them are used just as 'headers'
  const destinationMenuItemsWithUrlLength = destinationMenuItems?.filter(
    d => d.value?.url?.value
  ).length
  // number of columns for 'Destinations' menu
  const destinationMenuItemsCols =
    destinationMenuItemsWithUrlLength > 10
      ? Math.ceil(destinationMenuItemsWithUrlLength / 10)
      : 1

  // get number of items in header image overlay menu;
  // here we include all items, even the ones without URL (headers)
  const destinationMenuItemsLength = destinationMenuItems?.length
  // number of columns for header image overlay
  const destinationMenuItemsColsOverlay =
    destinationMenuItemsLength > 13
      ? Math.ceil(destinationMenuItemsLength / 13)
      : 1

  const overlayColumns = splitArrayEvenly(
    destinationMenuItems,
    destinationMenuItemsColsOverlay
  )

  const pageMenuItems = page?.menu?.value?.menuElements?.value
  const pageMenuIsFullWidth = page?.menu?.value?.isFullWidth?.value

  const imageArray = page?.headerImage?.value
  const headerImages = imageArray && imageArray.length && imageArray

  const isKnechtPage = _.includes(allProjectsDestinations, destinationSlug) // homepage and its pages
  const urlStart = !isKnechtPage
    ? `/${langSlug}${destinationSlug}/`
    : `/${langSlug}`

  const contactPage = page?.contactPage?.value
  let contactPagePath = `${urlStart}kontakt`
  if (contactPage) {
    // const { level2, level3, level4, level5, destination } = contactPage
    const { level2, level3, level4, level5, destinations } = contactPage
    let contactPageDstSlug
    if (destinations.value.length > 1) {
      const currentDst = destinations.value.filter(
        d => d.slug.value === destinationSlug
      )
      if (currentDst && currentDst.length > 0) {
        contactPageDstSlug = currentDst[0].slug.value
      } else {
        contactPageDstSlug = destinations.value[0].slug.value
      }
    } else {
      contactPageDstSlug = destinations.value[0].slug.value
    }

    const urlParts = [
      contactPageDstSlug, // destination.value.slug.value,
      level2 && level2.value,
      level3 && level3.value,
      level4 && level4.value,
      level5 && level5.value
    ]

    // destination 'knecht' is homepage, remove it from URL
    if (urlParts[0] === env.homepage_destination) {
      urlParts.shift()
    }
    contactPagePath = '/' + urlParts.filter(Boolean).join('/')
  }

  const [isMenuOpen, toggleMenu] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isSubmenuOpen, setSubmenuOpen] = useState(false)
  const [isSubSubmenuOpen, setSubSubmenuOpen] = useState(false)
  const { pathname } = useLocation()

  const getFeedbackPrefix = () => {
    const feedbackPrefix = headerSetting.feedbackPrefix || ''
    if (feedbackPrefix.length > 0 && urlStart.endsWith(feedbackPrefix)) {
      return ''
    }

    return feedbackPrefix
  }

  const renderDestinationMenuItems = (
    destinationMenuItems,
    isMobileMenu,
    showItemsWithoutUrl = true
  ) => {
    return destinationMenuItems?.map((country, index) => {
      const text = country.value?.text?.value
      const url = country.value?.url?.value
      const isActive = pathname.substring(1) === url
      const showSubSubMenu = isMobileMenu && isActive && pageMenuItems
      if (url) {
        return (
          <li key={index}>
            <div
              className={`${isSubSubmenuOpen === index ? 'menu-opened' : ''}`}
            >
              <div className='fl-has-submenu-container'>
                <Link to={`/${langSlug}${url}`}>{text}</Link>
                {showSubSubMenu && (
                  <button
                    className='fl-menu-toggle'
                    aria-label='open'
                    onClick={() => toggleSubSubmenu(index)}
                  />
                )}
              </div>

              {showSubSubMenu && (
                <ul
                  className={`sub-sub-menu${
                    isSubSubmenuOpen === index ? ' sub-menu-opened' : ''
                  }`}
                >
                  {pageMenuItems.map(item => {
                    const text = item.value?.text?.value
                    const url = item.value?.url?.value

                    return (
                      <li key={url}>
                        {isInternalUrl(url) ? (
                          <Link to={`/${langSlug}${url}`}>{text}</Link>
                        ) : (
                          <a href={url} target='_blank' rel='noreferrer'>
                            {text}
                          </a>
                        )}
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          </li>
        )
      } else {
        return (
          showItemsWithoutUrl && (
            <li key={index}>
              <strong>{text}</strong>
            </li>
          )
        )
      }
    })
  }

  const getStamp = image =>
    env.homepage_destination !== 'knecht' ? (
      <Link to={`/${langSlug}`}>{image}</Link>
    ) : (
      <>{image}</>
    )

  const renderOverlayMenu = cols => {
    const numOfCols = cols.length
    const className = `col-xs-${12 / numOfCols}`

    return (
      <div className='row'>
        {cols.map((col, index) => {
          return (
            <div className={className} key={index}>
              {col.map((item, index) => {
                const text = item.value?.text?.value
                const url = item.value?.url?.value

                return (
                  <li key={index}>
                    {url ? (
                      <Link to={`/${langSlug}${url}`}>{text}</Link>
                    ) : (
                      <strong>{text}</strong>
                    )}
                  </li>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  const toggleSubmenu = id => {
    if (id === isSubmenuOpen) {
      setSubmenuOpen(false)
      setSubSubmenuOpen(false)
    } else {
      setSubmenuOpen(id)
    }
  }
  const toggleSubSubmenu = id => {
    if (id === isSubSubmenuOpen) {
      setSubSubmenuOpen(false)
    } else {
      setSubSubmenuOpen(id)
    }
  }

  let langUrl = url

  // Search if in url is / if not add
  if (url.charAt(0) !== '/') {
    langUrl = '/' + url
  }

  return (
    <>
      <header
        style={{
          background: destinationColor
        }}
      >
        <div className='header-inner'>
          <div className='container'>
            <div className='header-top'>
              <div
                className={`header-top-left col-8 col has-stamp ${
                  !isKnechtPage ? 'visible-desktop' : ''
                }`}
              >
                <div className='col-inner'>
                  {getStamp(
                    <img
                      className='stamp stamp-knecht'
                      src={stamps[env.homepage_destination]}
                      alt='homepage stamp'
                      title='homepage stamp'
                    />
                  )}
                </div>
              </div>

              <div
                className={`header-top-left col-8 col ${
                  !isKnechtPage && destinationStampImage ? 'has-stamp' : ''
                } ${isKnechtPage ? 'visible-desktop' : ''}`}
              >
                {!isKnechtPage && destinationStampImage && (
                  <div className='col-inner'>
                    <Link to={`/${langSlug}${destinationSlug}`}>
                      <img
                        className='stamp stamp-destination'
                        src={destinationStampImage}
                        alt={`${destinationName} stempel`}
                        title={`${destinationName} stempel`}
                        loading='eager'
                      />
                    </Link>
                  </div>
                )}
              </div>

              <div className='header-top-center col-66 col'>
                <div className='col-inner'>
                  {env.languages?.displaySwitch && (
                    <div className='lang-switcher visible-desktop'>
                      {_.map(env.languages.settings, (item, id) => {
                        return (
                          <Link
                            key={id}
                            to={`/${item.slug.slice(0, -1)}${langUrl}`}
                            className={lang === id && 'selected'}
                          >
                            {item.name}
                          </Link>
                        )
                      })}
                    </div>
                  )}
                  <Link
                    to={
                      isKnechtPage
                        ? `/${langSlug}`
                        : `/${langSlug}${destinationSlug}`
                    }
                  >
                    {env.special_header && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: env.special_header || (
                            <>
                              <strong>{destinationName}</strong>reisen
                            </>
                          )
                        }}
                      ></span>
                    )}
                    {!env.special_header && (
                      <>
                        <strong>{destinationName}</strong>reisen
                      </>
                    )}
                  </Link>
                </div>
              </div>

              <div className='header-top-right col-8 col col-16'>
                <ul className='visible-desktop'>
                  <li>
                    <span>
                      <strong>knecht</strong>reisen
                    </span>
                  </li>
                  <li>
                    <img
                      src={logoReisegarantie}
                      alt='logo reisegarantie'
                      title='logo reisegarantie'
                      loading='eager'
                    />
                  </li>
                  <li>
                    <Link to={`${urlStart}ueber-uns`}>Über uns</Link>
                  </li>
                </ul>

                <div className='mobile-menu visible-mobile'>
                  <button
                    className='menu-mobile-toggle hamburger'
                    aria-label='MobileMenu'
                    onClick={() => toggleMenu(!isMenuOpen)}
                  >
                    <span className='svg-container'>
                      <svg
                        version='1.1'
                        className='hamburger-menu'
                        viewBox='0 0 512 512'
                      >
                        <rect
                          className='fl-hamburger-menu-top'
                          width='512'
                          height='102'
                        ></rect>
                        <rect
                          className='fl-hamburger-menu-middle'
                          y='205'
                          width='512'
                          height='102'
                        ></rect>
                        <rect
                          className='fl-hamburger-menu-bottom'
                          y='410'
                          width='512'
                          height='102'
                        ></rect>
                      </svg>
                    </span>
                  </button>

                  <nav>
                    <ul
                      id='mobile-menu'
                      className={isMenuOpen ? 'opened' : ''}
                      style={{
                        background: transparentize(0.2, destinationColor)
                      }}
                    >
                      <li
                        className={`${
                          isSubmenuOpen === 1 ? ' menu-opened' : ''
                        }`}
                      >
                        <div className='fl-has-submenu-container'>
                          <span>{destinationMenuTitle}</span>
                          <button
                            className='fl-menu-toggle'
                            aria-label='open'
                            onClick={() => toggleSubmenu(1)}
                          />
                        </div>

                        <ul
                          className={`sub-menu${
                            isSubmenuOpen === 1 ? ' sub-menu-opened' : ''
                          }`}
                        >
                          {renderDestinationMenuItems(
                            destinationMenuItems,
                            true
                          )}
                        </ul>
                      </li>
                      <li>
                        <Link to={`${urlStart}spezialisten-team`}>
                          Spezialisten-Team
                        </Link>
                      </li>
                      <li>{phone && <a href={`tel:${phone}`}>{phone}</a>}</li>
                      <li>
                        <Link to={contactPagePath}>Anfrage senden</Link>
                      </li>

                      <li
                        className={`${
                          isSubmenuOpen === 2 ? ' menu-opened' : ''
                        }`}
                      >
                        <div className='fl-has-submenu-container'>
                          <Link to={`${urlStart}ueber-uns`}>Über uns</Link>
                          <button
                            className='fl-menu-toggle'
                            aria-label='open'
                            onClick={() => toggleSubmenu(2)}
                          />
                        </div>
                        <ul
                          className={`sub-menu${
                            isSubmenuOpen === 2 ? ' sub-menu-opened' : ''
                          }`}
                        >
                          {isKnechtPage && headerSetting.hasFiliale && (
                            <li>
                              <Link to={`${urlStart}filiale`}>Filiale</Link>
                            </li>
                          )}
                          <li>
                            <Link
                              to={`${urlStart}${getFeedbackPrefix()}feedback`}
                            >
                              Feedback
                            </Link>
                          </li>
                          <li>
                            <Link to={`${urlStart}events`}>Events</Link>
                          </li>
                          <li>
                            <Link to={`${urlStart}nachhaltigkeit`}>
                              Nachhaltigkeit
                            </Link>
                          </li>
                          <li>
                            <Link to={`${urlStart}datenschutz`}>
                              Datenschutz
                            </Link>
                          </li>
                          <li>
                            <Link to={`${urlStart}knecht-reisegruppe`}>
                              Knecht Gruppe
                            </Link>
                          </li>
                          <li>
                            <Link to={`${urlStart}agb`}>AGB</Link>
                          </li>
                          <li>
                            <Link to={`${urlStart}impressum`}>Impressum</Link>
                          </li>
                          {jobsLink?.link && (
                            <li>
                              {jobsLink?.isExternal && <a href={jobsLink.link}>Jobs</a>} 
                              {!jobsLink?.isExternal && <Link to={`${urlStart}${jobsLink.link}`}>Jobs</Link>}
                            </li>
                          )}
                        </ul>
                      </li>
                      <li
                        id='menu-item-1136'
                        className='kneLogoType menu-item menu-item-type-custom menu-item-object-custom'
                      >
                        <a href='https://www.knecht-reisen.ch/'>
                          <b>knecht</b>reisen
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <nav className='header-menu menu-primary visible-desktop'>
              <ul>
                {/* eslint-disable-next-line */}
                <li
                  onMouseEnter={() => setIsVisible(true)}
                  onMouseLeave={() => setIsVisible(false)}
                >
                  {destinationMenuTitle}
                  <ul
                    className={`dropdown-menu destinations-menu cols-${destinationMenuItemsCols}`}
                    style={{
                      display: isVisible ? 'flex' : 'none',
                      background: showDestinationsOverlay
                        ? destinationColor
                        : transparentize(0.2, destinationColor)
                    }}
                  >
                    {renderDestinationMenuItems(
                      destinationMenuItems,
                      false,
                      false
                    )}
                  </ul>
                </li>
                <li>
                  <Link to={`${urlStart}spezialisten-team`}>
                    Spezialisten-Team
                  </Link>
                </li>
                <li>
                  <Link to={contactPagePath}>Anfrage senden</Link>
                </li>
                <li>{phone && <a href={`tel:${phone}`}>{phone}</a>}</li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <div
        className={`header-image-wrapper ${!headerImages ? 'no-image' : ''}`}
      >
        {headerImages && (
          <>
            <HeaderImages images={headerImages} />

            <div className='container visible-desktop'>
              {isRootPage && showDestinationsOverlay && destinationMenuItems && (
                <div className='destinations-overlay-wrapper'>
                  <ul
                    className={`destinations-overlay cols-${destinationMenuItemsColsOverlay}`}
                    style={{
                      background: transparentize(0.3, destinationColor)
                    }}
                  >
                    {/* {renderDestinationMenuItems(destinationMenuItems, false)} */}
                    {renderOverlayMenu(overlayColumns)}
                  </ul>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div
        className={`content-body ${!headerImages ? '' : 'visible-mobile'}`}
        style={{
          display: !headerImages ? 'none' : ''
        }}
      >
        <button
          className='btn-fill'
          style={{
            background: destinationColor,
            width: 'calc(50% - 1px)',
            marginRight: 1,
            fontFamily: 'DroidSansMono, monospace'
          }}
          onClick={() => {
            toggleMenu(true)
            setSubmenuOpen(1)
          }}
        >
          {destinationMenuTitle}
        </button>
        <Link to={`${urlStart}spezialisten-team`}>
          <button
            className='btn-fill'
            style={{
              background: destinationColor,
              width: '50%',
              fontFamily: 'DroidSansMono, monospace'
            }}
          >
            Spezialisten-Team
          </button>
        </Link>
      </div>

      <div
        className={`${
          pageMenuItems ? 'sticky' : 'non-sticky'
        } visible-desktop container`}
      >
        <div
          className='menu-secondary'
          style={{ background: destinationColor }}
        >
          {pageMenuItems ? (
            <ul
              className={`menu-destination-pages ${
                pageMenuIsFullWidth ? 'menu-full-width' : ''
              }`}
            >
              {pageMenuItems.map(item => {
                const text = item.value?.text?.value
                const url = item.value?.url?.value
                const isActive = pathname.substring(1) === url

                let sanitisedUrl = url
                if (env.homepage_destination === 'glur') {
                  // remove the "skandinavien/" segment from the slug - only for GLUR
                  sanitisedUrl = url.replace('skandinavien/', '')
                }

                return (
                  <li key={url} className={isActive ? 'active' : undefined}>
                    {isInternalUrl(url) ? (
                      <Link to={`/${langSlug}${sanitisedUrl}`}>{text}</Link>
                    ) : (
                      <a href={sanitisedUrl} target='_blank' rel='noreferrer'>
                        {text}
                      </a>
                    )}
                  </li>
                )
              })}
            </ul>
          ) : (
            <div className='destinations-pick'>
              {showDestinationsOverlay &&
              destinationMenuItems &&
              destinationMenuItems.length > 0 &&
              isRootPage
                ? 'Wählen Sie Ihre Traumdestination'
                : ''}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Header
