import React, { useEffect, useState, useRef } from 'react'

import DownArrowIcon from '../assets/icons/down-arrow.png'

// 96 px equals to 4 lines/rows
function ReadMoreText ({ children, maxHeight = 96 }) {

  const [isExpanded, setIsExpanded] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [width, setWidth] = useState()
  const textRef = useRef(null)

  const handleWindowSizeChange = () => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
  }, [])

  useEffect(() => {
      // check if the text height exceeds the maxHeight
      if (textRef.current.scrollHeight > maxHeight) {
          setIsOverflowing(true)
      } else {
        setIsOverflowing(false)
      }
  }, [children, maxHeight, width])

  const toggleReadMore = () => {
      setIsExpanded(!isExpanded)
  }

  return (
      <div>
          <div
            ref={textRef}
            style={{
                maxHeight: isExpanded ? `${textRef.current.scrollHeight}px` : `${maxHeight}px`,
                overflow: 'hidden',
                whiteSpace: 'pre-line',
                transition: 'max-height 1.3s ease',
            }}
            dangerouslySetInnerHTML={{ __html: children }} 
          />
          {isOverflowing && (
            <div
                className='read-more-element'
                onClick={toggleReadMore}
                onKeyPress={toggleReadMore}
                role='button'
                tabIndex='0'
            >
                <img
                    src={DownArrowIcon}
                    width={14}
                    alt='down arrow'
                    title='down arrow'
                    loading='lazy'
                    className={isExpanded ? 'top' : 'down'}
                  /><span>{isExpanded ? 'Mehr Lesen' : 'Mehr Lesen'}</span>
            </div>
          )}
      </div>
  )
}

export default ReadMoreText
